<template>
  <v-app id="reset">
    <v-main>
      <v-container fluid fill-height>
        <v-row align="center" justify="center">
          <v-col cols="12" md="5">
            <v-img :src="logo" max-height="90" contain/> 
          </v-col>
          <v-col cols="1" class="d-none d-md-flex">
            <div class="vl"></div>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="mx-auto" elevation="5" max-width="500">
              <v-card-title class="justify-center mb-3 main-title">
                Restablecer contraseńa
              </v-card-title>
              <v-card-text>
                <v-form ref="formReset" lazy-validation @submit.prevent>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="form.password" label="Contraseña" :rules="rules.password" type="password" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="form.password2" label="Confirmar contraseña" :rules="rules.password" type="password" :disabled="loading" outlined required autocomplete="off"></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row align="center" justify="space-around" class="pa-3">
                  <v-btn large rounded color="white" :disabled="loading" @click="back()">Volver</v-btn>
                  <v-btn large rounded color="secondary" class="black--text" :disabled="loading || !isValid || completed || form.password == '' 
                  || form.password2 == '' || form.password != form.password2" @click="resetPassword()">Continuar</v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
          {{ msgError }}
        </v-snackbar>
        <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
          {{ msgSuccess }}
        </v-snackbar>
      </v-container>
    </v-main>
    <main-footer/>
  </v-app>
</template>

<script>
import mainFooter from '@/components/Footer'

export default {
  name: 'reset',
  components: { mainFooter },
  data: () => ({
    host: process.env.VUE_APP_API_SERVER,
    name: process.env.VUE_APP_NAME,
    logo: require('@/assets/logo.png'),
    path: '/pub/reset/password',
    loading: false, 
    completed: false,
    error: false,
    msgError: '',
    success: false,
    msgSuccess: '',
    form: {
      email: '',
      password: ''
    },
    rules: {
      password: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length >= 8) || 'La contraseña debe contener mínimo 8 caracteres alfanuméricos, incluyendo al menos una letra y un número',
        v => (v && v.length <= 60) || 'La contraseña excede la longitud máxima',
        v => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-zñÑ\d@$!%*#?&]{8,}$/.test(v) || 'La contraseña debe contener mínimo 8 caracteres, incluyendo al menos una letra y un número'
      ],
    }
  }),
  mounted() {
    let id = this.$route.path.split('/')
    id = id[id.length - 1]
    let regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    if (regex.test(id)) {
      this.request = id
    }
    else {
      this.error = true
      this.msgError = 'La solicitud es incorrecta'
    }
  },
  computed: {
    isValid: function() {
      if (this.form.password != '' && this.form.password2 != '') {
        return this.$refs.formReset.validate()
      }
      return false
    }
  },
  methods: {
    clear() {
      this.form.password = ''
      this.form.password2 = ''
    },
    resetPassword() {
      this.loading = true
      let data = {
        codigo: this.request, 
        password: this.form.password
      }
      this.axios.post(this.host + this.path, { data })
        .then(response => {
          this.clear()
          this.completed = true
          this.success = true
          this.msgSuccess = 'La contraseña ha sido actualizada'
          setTimeout(() => {
            this.$router.push('/login')
          }, 2500);
        })
        .catch(error => {
          this.loading = false
          this.error = true
          this.msgError = error.response.data.message
        })
        .finally(() => {})
    },
    back() {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
  .vl {
    border-left: 1px solid rgb(255, 255, 255);
    height: 300px;
  }
  .main-title {
    font-size: 28px;
    color: #5271ff;
  }
</style>
